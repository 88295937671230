.select_picker {
    z-index: 100;
    -webkit-transition: height 0.5s;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
    transition: height 0.5s;
    position: absolute;

    overflow-y:auto;

    

    border-radius: 5px;
    border: 0px solid;
    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    background-color:#E8E8E8;
    font-size: 20px;
}

.select_item {
    background-color: #E8E8E8;
    vertical-align: middle;
    height: 15px;
    width: 200px;
    margin: auto;
    border-bottom: 1px solid #E8E8E8;
    padding: 10px;
    font-size: 14px;
    color: black;

    -webkit-transition: background-color 1s;
    -moz-transition: background-color 1s;
    -ms-transition: background-color 1s;
    -o-transition: background-color 1s;
    transition: background-color 1s;
}

.select_item_clicked {
    background-color:#E4A83C;
    vertical-align: middle;
    height: 15px;
    width: 200px;
    margin: auto;
    border-bottom: 1px solid #E4A83C;
    padding: 10px;
    font-size: 14px;
    color: black;

    -webkit-transition: background-color 1s;
    -moz-transition: background-color 1s;
    -ms-transition: background-color 1s;
    -o-transition: background-color 1s;
    transition: background-color 1s;
}

.select_picker_maps {
    z-index: 100;
    -webkit-transition: height 0.5s;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
    transition: height 0.5s;
    position: absolute;

    overflow-y:auto;

    

    border-radius: 5px;
    border: 0px solid;
    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    background-color:black;
    font-size: 20px;
}

.select_item_maps {
    background-color: black;
    vertical-align: middle;
    height: 13px;
    width: 78px;
    margin: auto;
    /* border-bottom: 1px solid #E8E8E8; */
    padding: 10px;
    font-size: 12px;
    color: white;
    text-align: center;

    -webkit-transition: background-color 1s;
    -moz-transition: background-color 1s;
    -ms-transition: background-color 1s;
    -o-transition: background-color 1s;
    transition: background-color 1s;
}

.select_item_clicked_maps {
    background-color:black;
    vertical-align: middle;
    height: 13px;
    width: 74px;
    margin: auto;
    border: 1px solid #E4A83C;
    padding: 10px;
    font-size: 12px;
    color: #E4A83C;
    border-radius: 5px;
    text-align: center;

    -webkit-transition: background-color 1s;
    -moz-transition: background-color 1s;
    -ms-transition: background-color 1s;
    -o-transition: background-color 1s;
    transition: background-color 1s;
}

.select_picker_symbols {
    z-index: 100;
    -webkit-transition: height 0.5s;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
    transition: height 0.5s;
    position: absolute;

    overflow-y:auto;

    

    border-radius: 5px;
    border: 0px solid;
    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    background-color:#434246;
    font-size: 20px;
}

.select_item_symbols {
    background-color: #434246;
    vertical-align: middle;
    height: 15px;
    width: 200px;
    margin: auto;
    /* border-bottom: 1px solid #E8E8E8; */
    padding: 10px;
    font-size: 14px;
    color: white;

    -webkit-transition: background-color 1s;
    -moz-transition: background-color 1s;
    -ms-transition: background-color 1s;
    -o-transition: background-color 1s;
    transition: background-color 1s;
}

.select_item_clicked_symbols {
    background-color:#434246;
    vertical-align: middle;
    height: 15px;
    width: 200px;
    margin: auto;
    border: 1px solid #E4A83C;
    padding: 10px;
    font-size: 14px;
    color: #E4A83C;
    border-radius: 5px;

    -webkit-transition: background-color 1s;
    -moz-transition: background-color 1s;
    -ms-transition: background-color 1s;
    -o-transition: background-color 1s;
    transition: background-color 1s;
}

.button_with_confirmation{
    position: absolute;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.3);
    border: 1px solid #434246;

    font-size: 23px;
    color: white;
    background-color: #18181C;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    cursor: grab;    
}

.confirmation_layer{
    position: absolute;
    border-radius: 5px;
    background-color: chocolate;
    overflow: hidden;

    font-size: 23px;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    cursor: grab;

    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    -ms-transition: width 0.3s;
    -o-transition: width 0.3s;
    transition: width 0.3s;
    
}

.input_text{
    padding-left: 10px;
    background-color:#020107;    
    color: white;

    border-radius: 8px;

    border:1px solid #E4A83C;
    outline:3;
    
}




