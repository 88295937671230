.simple-keyboard.hg-theme-default.myTheme {
    /* border: 10px solid #18181C99; */
    border-radius: 10px;
    margin: 10px;
    width: calc(100% - 20px);
    
    
    /* background-color: rgba(2,1,7,0.5); */
    background-color: #18181C;

    /* backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px); */

    padding: 10px;

    /* box-shadow: 0px 10px 20px #00000080; */
}

.simple-keyboard.hg-layout-default .hg-button.hg-light-gray {
    background: #717171;
    color: white;
    border: 0;
}

.simple-keyboard.hg-layout-default .hg-button.hg-enter {
    background: #717171;
    color: white;
    border: 0;
}

.simple-keyboard.hg-layout-default .hg-button.hg-gray {
    background: #555555;
    color: white;
    border: 0;
}

.simple-keyboard.hg-layout-default .hg-button.hg-space {
    background: #383838;
    color: white;
    border: 0;
}

.simple-keyboard.hg-layout-default .hg-button.hg-dark_gray {
    background: #1C1C1C;
    color: white;
    border: 0;
}

