body {
    overflow: hidden;
    background-color: transparent;
    /* Hide scrollbars */
}

.hls_stream_url {
    width: 580px;
    margin-left: 10px;
    margin-top: 4px;
}

.login_iframe {
    width: 600px;
    height: 900px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.6, 1.6);
    border-radius: 20px;
    border: 0px solid;
    display: block; 
    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
}

.sites_component {
    width: 500px;
    height: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    border: 0px solid;
    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    background-color: aliceblue;
    font-size: 20px;
    padding: 50px
}


.search_sites_component {
    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    flex-direction: column;
    pointer-events: auto;
}




.video_component {
    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    border-radius: 5px;
    background-color: black;
    pointer-events: auto;
}



.main_window_component {
    position: absolute;
    pointer-events: none;

    /* -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; 
  user-select: none; */
}

.map_container_component {
    position: absolute;
    pointer-events: auto;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
}

.touch_component {
    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    
    
    pointer-events: auto;
    width: auto; 
    height: auto;
    
    
}

.touch_component_anim {
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s;

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    
    
    pointer-events: auto;
    width: auto; 
    height: auto;
    background-color: transparent;    
}

.notouch_component {
    position: absolute;
    will-change: transform;
    cursor: grab;
    
    overflow: hidden;
    
    pointer-events: auto;
    width: 100px;
    height: 100px;
}

.joystick_component {
    position: absolute;
    z-index: 999;
}

.login_component {
    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.3);
    pointer-events: auto;
}

.no_events{
    opacity: 0.2;
}


.keyboard_component {
    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;


    width: 600px;
    height: 270px;
    pointer-events: auto;
}

textarea:focus,
input:focus {
    outline: none;
}

.keyboard_input {
    width: 596px;
    height: 30px;
    border-radius: 5px;
    border: 0px solid;
    margin-bottom: 5px;
}



/* Project component */
.project_component {
    border-radius: 0px 15px 20px 25px;
    -webkit-transition: height 0.5s;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
    transition: height 0.5s;

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 980px;
    height: 250px;

    background-color: rgba(0, 0, 0, 0.3);

    pointer-events: auto;
}

.project_component_anim {
    border-radius: 0px 15px 20px 25px;
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s;

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 980px;
    height: 250px;

    background-color: rgba(0, 0, 0, 0.5);

    pointer-events: auto;
}

.readonly_input {
    text-align: center;
    margin-top: 10px;
    font-size: 30px;
    width: 100%;
    height: 30px;
    border-radius: 5px;
    border: 0px solid;
    background-color: transparent;
    color: darkgray
    /* pointer-events: none; */
}

.input_text {
    text-align: center;
    margin-top: 10px;
    height: 25px;
    border-radius: 5px;
    border: 0px solid;
    background-color: white;
    color: black;
    font-size: 15px;
    
    
    /* pointer-events: none; */
}

.projects_list_component {
    width: 940px;
    height: 382px;
    position: absolute;

    overflow-y:auto;

    border-radius: 5px;
    border: 0px solid;
    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    background-color: aliceblue;
    font-size: 20px;

}

.project_button {
    position: absolute;
    background-color: transparent;
    /* box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.3); */

    width: 200px;
    height: 50px;
    border-radius: 0px 10px 15px 15px;
    border: 1px solid white;

    font-size: 20px;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    cursor: grab;
}

.close_button {
    position: absolute;
    background-color: transparent;
    

    width: 50px;
    height: 50px;
    
    

    font-size: 20px;
    color: black;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    font-size: 30px;
    cursor: grab;
}

.functional_button {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    /* box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.3); */

    width: 200px;
    height: 50px;
    border-radius: 0px 10px 15px 15px;
    border: 1px solid white;

    font-size: 20px;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    cursor: grab;
}

.pressed_functional_button {
    position: absolute;
    background-color: rgba(0, 0, 0, 1);
    /* box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.3); */

    width: 200px;
    height: 50px;
    border-radius: 0px 10px 15px 15px;
    border: 1px solid white;

    font-size: 20px;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    cursor: grab;
}

.load_project_button{
    position: absolute;
    background-color: transparent;
    /* box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.3); */

    width: 200px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid white;

    font-size: 20px;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    cursor: grab;
}

.site_button {
    background-color: aliceblue;
    vertical-align: middle;
    height: 30px;
    margin: auto;
    border-bottom: 1px solid gainsboro;
    padding: 10px;

    -webkit-transition: background-color 1s;
    -moz-transition: background-color 1s;
    -ms-transition: background-color 1s;
    -o-transition: background-color 1s;
    transition: background-color 1s;
}

.site_button_clicked {
    background-color: gainsboro;
    vertical-align: middle;
    height: 30px;
    margin: auto;
    border-bottom: 1px solid gainsboro;
    padding: 10px;

    -webkit-transition: background-color 1s;
    -moz-transition: background-color 1s;
    -ms-transition: background-color 1s;
    -o-transition: background-color 1s;
    transition: background-color 1s;
}

/* Project component */

/* Drawing component */
.drawing_component {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    border-radius: 10px;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 880px;
    height: 200px;

    background-color: rgba(255, 255, 255, 0.9);

    pointer-events: auto;
}

.drawing_component_anim {
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s;

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    border-radius: 10px;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 880px;
    height: 200px;

    background-color: rgba(255, 255, 255, 0.9);

    pointer-events: auto;
}

.drawing_button {
    position: absolute;
    background-color: red;
    /* box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.3); */

    width: 100px;
    height: 50px;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.graphic_button {
    position: absolute;
    background-color: orange;
    /* box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.3); */

    width: 100px;
    height: 50px;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
}
.graphic_button_clicked {
    position: absolute;
    background-color: darkorange;
    /* box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.3); */

    width: 100px;
    height: 50px;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.drawing_button_clicked {
    position: absolute;
    background-color: brown;

    width: 100px;
    height: 50px;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
}


.color_button {
    position: absolute;
    
    /* box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.3); */

    width: 80px;
    height: 80px;
    border-radius: 5px;
    border-style: solid;
    border-color: transparent;

    display: flex;
    justify-content: center;
    align-items: center;
}

.color_button_clicked {
    position: absolute;
    
    /* box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.3); */

    width: 80px;
    height: 80px;
    border-radius: 5px;
    border-color: gray;
    border-style: solid;
    

    display: flex;
    justify-content: center;
    align-items: center;
}

.tab_panel {
    overflow-y: auto;
    height: 374px;
}

/* drawing component */

/* Alert Component */
.alert_component {
    -webkit-transition: height 0.5s;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
    transition: height 0.5s;

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    border-radius: 10px;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 955px;
    height: 100px;

    background-color: rgba(165, 61, 56, 0.7);

    pointer-events: auto;

    vertical-align: middle;
    margin: auto;
    color:white;
    font-size: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.invite_component {
    -webkit-transition: height 0.5s;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
    transition: height 0.5s;

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    border-radius: 10px;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 955px;
    height: 100px;

    background-color: rgba(61, 165, 56, 0.7);

    pointer-events: auto;

    vertical-align: middle;
    margin: auto;
    color:white;
    font-size: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.blob {
	background: rgba(255, 0, 0, 0.4);
    margin-top: -2px;
	height: 38px;
	width: 50px;

	box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
	transform: scale(1);
	animation: pulse 2s infinite;

    position: absolute;
}

.blobimg{
    position: absolute;
}

@keyframes pulse {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
	}

	70% {
		transform: scale(1.3);
		box-shadow: 0 0 0 10px rgba(2555, 0, 0, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
	}
}

/* Alert Component */

/* Device Component */
.device_component {
    -webkit-transition: height 0.5s ease-in, width 0.5s ease-in;
    -moz-transition: height 0.5s ease-in, width 0.5s ease-in;
    -ms-transition: height 0.5s ease-in, width 0.5s ease-in;
    -o-transition: height 0.5s ease-in, width 0.5s ease-in;
    transition:  height 0.5s ease-in, width 0.5s ease-in;
    

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    border-radius: 20px;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 955px;
    height: 100px;

    background-color: rgba(255, 255, 255, 0.3);

    pointer-events: auto;    
    
    color:white;
    font-size: 30px;

    display: flex;
}

.device_component_anim {
    -webkit-transition: height 0.5s ease-in, width 0.5s ease-in;
    -moz-transition: height 0.5s ease-in, width 0.5s ease-in;
    -ms-transition: height 0.5s ease-in, width 0.5s ease-in;
    -o-transition: height 0.5s ease-in, width 0.5s ease-in;
    transition:  height 0.5s ease-in, width 0.5s ease-in;

    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s;
    

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    border-radius: 20px;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 955px;
    height: 100px;

    background-color: rgba(255, 255, 255, 0.7);

    pointer-events: auto;    
    
    color:white;
    font-size: 30px;

    display: flex;
}

.edit_symbol_component {
    -webkit-transition: height 0.5s ease-in, width 0.5s ease-in;
    -moz-transition: height 0.5s ease-in, width 0.5s ease-in;
    -ms-transition: height 0.5s ease-in, width 0.5s ease-in;
    -o-transition: height 0.5s ease-in, width 0.5s ease-in;
    transition:  height 0.5s ease-in, width 0.5s ease-in;
    

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    border-radius: 20px;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 955px;
    height: 100px;

    background-color: rgba(255, 255, 255, 0.7);

    pointer-events: auto;    
    
    color:black;
    

    display: flex;
    
}

.edit_symbol_component_anim {
    -webkit-transition: height 0.5s ease-in, width 0.5s ease-in;
    -moz-transition: height 0.5s ease-in, width 0.5s ease-in;
    -ms-transition: height 0.5s ease-in, width 0.5s ease-in;
    -o-transition: height 0.5s ease-in, width 0.5s ease-in;
    transition:  height 0.5s ease-in, width 0.5s ease-in;

    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s;
    

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    border-radius: 20px;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 955px;
    height: 100px;

    background-color: rgba(255, 255, 255, 0.7);

    pointer-events: auto;    
    
    color:black;
    

    display: flex;
    
}

.device_component_title {
    color:black;
    font-size: 30px;
    margin: 0 auto;
}

.device_component_subtitle {
    color:white;
    font-size: 20px;
    margin-top: 0px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.device_component_properties {
    color:black;
    font-size: 20px;
    margin-left: 20px;
    margin-bottom: 5px;
}

.gray_image{
    filter:grayscale(100%);
    -webkit-filter: grayscale(100%);

    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
 }

 .selected_image{
    filter:grayscale(100%);
    filter:hue-rotate(0deg);
    -webkit-filter: grayscale(100%);
    -webkit-filter: hue-rotate(0deg);

    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
 } 

 .not_draggable_image{
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
 }
 

/* Device Component */


.symbol_text{
    position: absolute;
    color:white;
    font-size: 9px;
    white-space: nowrap;
    text-shadow: 0 0 1px black, 0 0 1px black, 0 0 4px black;
 }

 /* .marker{
    display: flex;
    align-items: center;
    justify-content: center;
 } */

 .marker_icon{
    position: absolute;
    height: 25px;
    left:13px;
    top:5px;
 }

 .input_checkbox {
    height: 20px;
    width: 20px;
    position: absolute;
    background-color: #0D0D12;
  
}
