.browser_component {
    border-radius: 20px 20px 20px 20px;
    -webkit-transition: height 0.5s;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
    transition: height 0.5s;

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */

    background-color: rgba(0, 0, 0, 0.5);

    pointer-events: auto;
}

.browser_component_anim {
    border-radius: 20px 20px 20px 20px;
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s;

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */


    background-color: rgba(0, 0, 0, 0.5);

    pointer-events: auto;
}