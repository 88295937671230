body {
    overflow: hidden;
    background-color: transparent;
    /* Hide scrollbars */
}

@keyframes smooth-appear-dashboard {
    100% {
        left: 0px;
        opacity: 1;
    }
}

.dashboard {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.3);
    /* position: absolute; */
    /* top: 0px;
    left: 0px; */
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    pointer-events: auto;

    position: fixed;
    left: -100%;
    opacity: 0;
    top: 0px;
    /* transform: translateX(-50%); */
    animation: smooth-appear-dashboard 1s ease forwards;
}

@keyframes smooth-disappear-dashboard {
    100% {
        left: -100%;
        opacity: 0;
    }
}

.dashboard_close {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.3);
    /* position: absolute; */
    /* top: 0px;
    left: 0px; */
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    pointer-events: auto;

    position: fixed;
    left: 0px;
    opacity: 1;
    top: 0px;
    /* transform: translateX(-50%); */
    animation: smooth-disappear-dashboard 2s ease forwards;
}

.hls_stream_url {
    width: 580px;
    margin-left: 10px;
    margin-top: 4px;
}

.login_iframe_bck {
    width: 1100px;
    height: 940px;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    border: 20px solid;
    display: block; 
    border-color:rgba(2,1,7,0.3);

    background-color: rgba(2,1,7,0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0px 10px 20px #00000080;

    overflow: hidden;
}

.login_iframe {
    width: 1100px;
    height: 970px;
    transform: scale(1.15);

    border-radius: 20px;
    border: 0px solid;
    display: block; 
}

.sites_component {
    width: 500px;
    height: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    border: 0px solid;
    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    background-color: aliceblue;
    font-size: 20px;
    padding: 50px
}


.search_sites_component {
    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    flex-direction: column;
    pointer-events: auto;
}



.inner_video_component{
    -webkit-transition: transform 0.3s;
    -moz-transition: transform 0.3s;
    -ms-transition: transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
    top: -3%;
    position: absolute;
}

.sound_on{
    background-color: white;
    font-size: 20px;
}
.sound_off{
    background-color: red;
    font-size: 20px;
}

.video_component_anim {
    -webkit-transition: border-radius 0.3s;
    -moz-transition: border-radius 0.3s;
    -ms-transition: border-radius 0.3s;
    -o-transition: border-radius 0.3s;
    transition: border-radius 0.3s;

    -webkit-transition: transform 0.3s;
    -moz-transition: transform 0.3s;
    -ms-transition: transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    /* border-radius: 50%; */
    /* background-color: rgba(2,1,7,0.3); */
    pointer-events: auto;
}

.video_component_button {
    cursor: pointer;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;


    flex-direction: column;
    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    pointer-events: auto;
}

.video_component {
    
    border-radius: 15px;
    -webkit-transition: border-radius 0.5s;
    -moz-transition: border-radius 0.5s;
    -ms-transition: border-radius 0.5s;
    -o-transition: border-radius 0.5s; 
    transition: border-radius 0.5s;

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    /* border-radius: 50%; */
    /* border: 2px solid white; */
    /* background-color: black; */
    pointer-events: auto;
    backdrop-filter: blur(10px);
    /* border: 7.5px solid rgba(2,1,7,0.3) !important; */
}

.sound_bttn_video_component {
    

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    /* border-radius: 50%; */
    /* border: 2px solid white; */
    /* background-color: black; */
    pointer-events: auto;
    /* border: 7.5px solid rgba(2,1,7,0.3) !important; */
}





.main_window_component {
    position: absolute;
    pointer-events: none;

    /* -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; 
  user-select: none; */
}

.map_container_component {
    position: absolute;
    pointer-events: auto;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
}

.los_component {
    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;

    overflow: hidden;
    overflow-y: none;
    
    
    pointer-events: auto;
    width: auto; 
    height: auto;
    background-color: transparent;   
    
    /* backdrop-filter: blur(12.7px);
    -webkit-backdrop-filter: blur(12.7px);
    background-color: rgba(0, 0, 0, 0.5); */
}

.video_touch_component {
    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;

    overflow: hidden;
    
    
    pointer-events: auto;
    width: auto; 
    height: auto;
    /* background-color: transparent;    */
    
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.3);
}

.touch_component {
    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;

    overflow: hidden;
    overflow-y: none;
    
    
    pointer-events: auto;
    width: auto; 
    height: auto;
    /* background-color: transparent;    */
    
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.3);
}

.zoomIn {
    transition: width 2s ease, height 2s ease; 
    /* transform: scale(6);  */
}

.touch_component_anim {
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s;

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    
    
    pointer-events: auto;
    width: auto; 
    height: auto;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.3);  
}

.appearAnimation {
    transition: width 4s ease-in-out, height 4s ease-in-out, transform 4s ease-in-out;
    transform-origin: center center;
}

.notouch_component {
    position: absolute;
    will-change: transform;
    cursor: grab;
    
    overflow: hidden;
    
    pointer-events: auto;
    width: 100px;
    height: 100px;
}

.joystick_component {
    position: absolute;
    z-index: 999;
}

.login_component {
    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.3);
    pointer-events: auto;
}

.no_events{
    opacity: 0.2;
}


.keyboard_component {
    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;


    width: 600px;
    height: 270px;
    pointer-events: auto;
}

textarea:focus,
input:focus {
    outline: none;
}

.keyboard_input {
    width: 596px;
    height: 30px;
    border-radius: 5px;
    border: 0px solid;
    margin-bottom: 5px;
}



/* Project component */
.project_component {
    border-radius: 20px 20px 20px 20px;
    -webkit-transition: height 0.5s;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
    transition: height 0.5s;

    

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 980px;
    height: 250px;

    background-color: rgba(2,1,7,0.3);

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    pointer-events: auto;
    box-shadow: 0px 10px 20px #00000080;
}

.project_component_anim {
    border-radius: 0px 15px 20px 25px;
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s;

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 980px;
    height: 250px;

    background-color: rgba(2,1,7,0.3);

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    pointer-events: auto;
    box-shadow: 0px 10px 20px #00000080;
}

.readonly_input {
    text-align: center;
    margin-top: 10px;
    font-size: 30px;
    width: 100%;
    height: 30px;
    border-radius: 5px;
    border: 0px solid;
    background-color: transparent;
    color: darkgray
    /* pointer-events: none; */
}

.input_text {
    text-align: center;
    margin-top: 10px;
    height: 25px;
    border-radius: 5px;
    border: 0px solid;
    background-color: white;
    color: black;
    font-size: 15px;
    
    
    /* pointer-events: none; */
}

.projects_list_component {
    width: 620px;
    height: 382px;
    position: absolute;

    overflow-y:auto;

    border-radius: 5px;
    border: 0px solid;
    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    background-color: aliceblue;
    font-size: 20px;

}

.projects_list_component::-webkit-scrollbar-thumb:vertical:hover {
    background-color: grey;
}

.files_list_component {
    height: 150px;
    position: absolute;
    display:inline-block;
    border-radius: 5px;
    padding-left: 15px;
    font-size: 18px;
}
.files_list_component::-webkit-scrollbar-thumb:horizontal:hover {
    background-color: grey;
}
.files_list_component::-webkit-scrollbar{
        height: 10px;
}

@keyframes smooth-appear {
    100% {
        bottom: 60px;
        opacity: 1;
    }
}

.files_component {
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    flex-direction: column;
    border-radius: 10px;
    pointer-events: auto;
    display:inline-block;
    background-color: rgba(2,1,7,0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    position: fixed;
    bottom: -100%;
    opacity: 0;
    left: 50%;
    transform: translateX(-50%);
    animation: smooth-appear 1s ease forwards;
}

@keyframes smooth-disappear {
    0% {
        bottom: 60px;
        opacity: 1;
    }
    100% {
        bottom: -100%;
        opacity: 0;
    }
}
.files_component_close {
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    flex-direction: column;
    border-radius: 10px;
    pointer-events: auto;
    display:inline-block;
    background-color: rgba(2,1,7,0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    animation: smooth-disappear 1s ease-in forwards;
}
.add_file_button {
    width: 150px;
    height: 150px;
    box-shadow: 0 0 20px black;
    clip-path: inset(0px -20px 0px 0px);
    display: inline-block;
    background-color: transparent;
}
@keyframes anvil {
    0% {
        transform: matrix(0.5, 0, 0, 0.5, 0, -130);
        opacity: 0;
    }
    100% {
        transform: matrix(1, 0, 0, 1, 0, -130);
        opacity: 1;
    }
}
.file_button_popup{
    vertical-align: middle;
    width: 110px;
    height: 130px;
    margin: auto;
    animation: anvil 2s ease forwards;
    font-size: 18px;
    margin-right: 15px;
    display: inline-block;
    border-radius: 10px;
    background-color: black;
}
.file_button {
    vertical-align: middle;
    width: 110px;
    height: 130px;
    margin: auto;
    font-size: 18px;
    
    margin-right: 15px;
    display: inline-block;
    border-radius: 10px;
    background-color: black;
}
@keyframes anvil_copy {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 0.5;
    }
}
.file_button_copy {
    vertical-align: middle;
    width: 110px;
     
    height: 130px;
     
    margin: auto;
    font-size: 18px;
    margin-right: 15px;
    opacity: 0.5;
    border-radius: 10px;
    background-color: black;
    animation: anvil_copy 2s ease forwards;
}

.icon_img {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    transition: opacity 0.2s ease-in
}
.div_icon {
    position: absolute; 
    height: 80px; 
    width: 90px;
    transition: transform 0.2s ease-in
}
.countdown_button {
    border-radius: 5px;
    width: 100px;
    height: 30px;
    color: white;
    background: linear-gradient(to right, red 50%, black 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    transition: all 2s ease-out;
}

/* .files_list_component {
    
    height: 150px;
    position: absolute;
    display:inline-block;

    border-radius: 5px;
    padding-left: 15px;
    
    
    font-size: 18px;

    
}

@keyframes smooth-appear {
    to{
        bottom: 20px;
        opacity:1;
    }
}

.files_component {
    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    flex-direction: column;
    border-radius: 10px;
    pointer-events: auto;
    display:inline-block;

    background-color: rgba(2,1,7,0.3);

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    bottom: -100%;
    opacity: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    animation: smooth-appear 1s ease forwards;
}

@keyframes smooth-disappear {
    from {
        bottom: 20px;
        opacity:1;
    }
    to{
        bottom: -100%;
        opacity: 0;
    }
}

.files_component_close{
    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    flex-direction: column;
    border-radius: 10px;
    pointer-events: auto;
    display:inline-block;

    background-color: rgba(2,1,7,0.3);

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    animation: smooth-disappear 1s ease-in forwards;
}

.add_file_button {


    width: 150px;
    height: 150px;
    box-shadow: 10px 0px 30px -5px  #18181C;

    display: inline-block;
    background-color: transparent;
}

.file_button {
    vertical-align: middle;
    width: 110px;
    height: 130px;
    margin: auto;
    

    font-size: 18px;

    margin-right: 15px;

    display: inline-block;

    border-radius: 10px;

    background-color: black;
}

.file_button_copy {
    vertical-align: middle;
    width: 110px;
    height: 130px;
    margin: auto;
    

    font-size: 18px;

    margin-right: 15px;


    border-radius: 10px;

    background-color: black;
}
.countdown_button {
    border-radius: 5px;
    width: 100px;
    height: 30px;
    color: white;
    background: linear-gradient(to right, red 50%, black 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    transition: all 2s ease-out;
} */


.project_button {
    position: absolute;
    background-color: transparent;
    /* box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.3); */

    width: 200px;
    height: 50px;
    border-radius: 0px 10px 15px 15px;
    border: 1px solid white;

    font-size: 20px;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    cursor: grab;
}

.close_button {
    position: absolute;
    background-color: transparent;
    

    width: 50px;
    height: 50px;
    
    

    font-size: 20px;
    color: black;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    font-size: 30px;
    cursor: grab;
}

.functional_button {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    /* box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.3); */

    width: 200px;
    height: 50px;
    border-radius: 0px 10px 15px 15px;
    border: 1px solid white;

    font-size: 20px;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    cursor: grab;
}

.pressed_functional_button {
    position: absolute;
    background-color: rgba(0, 0, 0, 1);
    /* box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.3); */

    width: 200px;
    height: 50px;
    border-radius: 0px 10px 15px 15px;
    border: 1px solid white;

    font-size: 20px;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    cursor: grab;
}

.load_project_button{
    position: absolute;
    background-color: transparent;
    /* box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.3); */

    width: 200px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid white;

    font-size: 20px;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    cursor: grab;
}

.load_project_button_clicked{
    position: absolute;
    background-color: #18181C;
    /* box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.3); */
    color: #E4A83C;

    width: 200px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #434246;

    font-size: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    cursor: grab;
}


.load_project_button_notclicked{
    position: absolute;
    background-color: #18181C;
    /* box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.3); */
    color: #434246;

    width: 200px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #434246;

    font-size: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    cursor: grab;
}

.load_project_button_inactive{
    position: absolute;
    background-color: #18181C;
    /* box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.3); */
    color: white;

    width: 200px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #434246;

    font-size: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    cursor: grab;
}

.add_los_active{
    position: absolute;
    background-color: #E4A83C;
    /* box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.3); */
    color: #18181C;

    width: 200px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #434246;

    font-size: 15px;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    cursor: grab;
}

.add_los_inactive{
    position: absolute;
    background-color: #18181C;
    /* box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.3); */
    color: white;

    width: 200px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #434246;

    font-size: 15px;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    cursor: grab;
}

.load_project_button_anulare{
    position: absolute;
    background-color: #18181C;
    /* box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.3); */
    color: white;

    width: 200px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #434246;

    font-size: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    cursor: grab;
}

.site_button {
    background-color: #18181C;
    vertical-align: middle;
    height: 30px;
    margin: auto;
    border-bottom: 1px solid #18181C;
    padding: 10px;

    -webkit-transition: background-color 1s;
    -moz-transition: background-color 1s;
    -ms-transition: background-color 1s;
    -o-transition: background-color 1s;
    transition: background-color 1s;
}



.site_button_clicked {
    background-color: #18181C;
    border: 2px solid #E4A83C;
    vertical-align: middle;
    height: 30px;
    margin: auto;
    border-bottom: 1px solid #E4A83C;
    padding: 10px;

    -webkit-transition: background-color 1s;
    -moz-transition: background-color 1s;
    -ms-transition: background-color 1s;
    -o-transition: background-color 1s;
    transition: background-color 1s;
}

/* Project component */

/* Drawing component */
.drawing_component {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    border-radius: 10px;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 880px;
    height: 200px;

    background-color: rgba(255, 255, 255, 0.9);

    pointer-events: auto;

    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.drawing_component_anim {
    -webkit-transition: transform 0.3s;
    -moz-transition: transform 0.3;
    -ms-transition: transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    border-radius: 10px;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 880px;
    height: 200px;

    background-color: rgba(255, 255, 255, 0.9);

    pointer-events: auto;
}

.drawing_button {
    position: absolute;
    background-color: red;
    /* box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.3); */

    width: 100px;
    height: 50px;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.graphic_button {
    position: absolute;
    background-color: orange;
    /* box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.3); */

    width: 100px;
    height: 50px;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
}
.graphic_button_clicked {
    position: absolute;
    background-color: darkorange;
    /* box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.3); */

    width: 100px;
    height: 50px;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.drawing_button_clicked {
    position: absolute;
    background-color: brown;

    width: 100px;
    height: 50px;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
}


.color_button {
    position: absolute;
    
    /* box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.3); */

    width: 80px;
    height: 80px;
    border-radius: 5px;
    border-style: solid;
    border-color: transparent;

    display: flex;
    justify-content: center;
    align-items: center;
}

.color_button_clicked {
    position: absolute;
    
    /* box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.3); */

    width: 80px;
    height: 80px;
    border-radius: 5px;
    border-color: gray;
    border-style: solid;
    

    display: flex;
    justify-content: center;
    align-items: center;
}

.tab_panel {
    overflow-y: auto;
    height: 374px;
}

/* drawing component */

/* Alert Component */
.alert_component {
    -webkit-transition: height 0.5s;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
    transition: height 0.5s;

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    border-radius: 10px;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 955px;
    height: 100px;

    background-color: rgba(165, 61, 56, 0.3);

    pointer-events: auto;

    vertical-align: middle;
    margin: auto;
    color:white;
    font-size: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.invite_component {
    -webkit-transition: height 0.5s;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
    transition: height 0.5s;

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    border-radius: 10px;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 955px;
    height: 100px;

    background-color: rgba(61, 165, 56, 0.5);

    pointer-events: auto;

    vertical-align: middle;
    margin: auto;
    color:white;
    font-size: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.blob {
	background: rgba(255, 0, 0, 0.4);
    margin-top: -0px;
	height: 34px;
	width: 35px;

	box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
	transform: scale(1);
	animation: pulse 2s infinite;

    position: absolute;
}

.blobimg{
    position: absolute;
}

@keyframes pulse {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
	}

	70% {
		transform: scale(1.3);
		box-shadow: 0 0 0 10px rgba(2555, 0, 0, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
	}
}

/* Alert Component */

/* Device Component */
.device_component {
    -webkit-transition: height 0.5s ease-in, width 0.5s ease-in;
    -moz-transition: height 0.5s ease-in, width 0.5s ease-in;
    -ms-transition: height 0.5s ease-in, width 0.5s ease-in;
    -o-transition: height 0.5s ease-in, width 0.5s ease-in;
    transition:  height 0.5s ease-in, width 0.5s ease-in;
    

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    border-radius: 20px;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 955px;
    height: 100px;

    background-color: rgba(255, 255, 255, 0.3);

    pointer-events: auto;    
    
    color:white;
    font-size: 30px;

    display: flex;
}

.device_component_anim {
    -webkit-transition: height 0.5s ease-in, width 0.5s ease-in;
    -moz-transition: height 0.5s ease-in, width 0.5s ease-in;
    -ms-transition: height 0.5s ease-in, width 0.5s ease-in;
    -o-transition: height 0.5s ease-in, width 0.5s ease-in;
    transition:  height 0.5s ease-in, width 0.5s ease-in;

    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s;
    

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    border-radius: 20px;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 955px;
    height: 100px;

    background-color: rgba(255, 255, 255, 0.7);

    pointer-events: auto;    
    
    color:white;
    font-size: 30px;

    display: flex;
}

.edit_symbol_component {
    -webkit-transition: height 0.5s ease-in, width 0.5s ease-in;
    -moz-transition: height 0.5s ease-in, width 0.5s ease-in;
    -ms-transition: height 0.5s ease-in, width 0.5s ease-in;
    -o-transition: height 0.5s ease-in, width 0.5s ease-in;
    transition:  height 0.5s ease-in, width 0.5s ease-in;
    

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    border-radius: 20px;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 955px;
    height: 100px;

    background-color: rgba(255, 255, 255, 0.7);

    pointer-events: auto;    
    
    color:black;
    

    display: flex;
    
}

.edit_symbol_component_anim {
    -webkit-transition: height 0.5s ease-in, width 0.5s ease-in;
    -moz-transition: height 0.5s ease-in, width 0.5s ease-in;
    -ms-transition: height 0.5s ease-in, width 0.5s ease-in;
    -o-transition: height 0.5s ease-in, width 0.5s ease-in;
    transition:  height 0.5s ease-in, width 0.5s ease-in;

    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s;
    

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    border-radius: 20px;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 955px;
    height: 100px;

    background-color: rgba(255, 255, 255, 0.7);

    pointer-events: auto;    
    
    color:black;
    

    display: flex;
    
}

.device_component_title {
    color:black;
    font-size: 30px;
    margin: 0 auto;
}

.device_component_subtitle {
    color:white;
    font-size: 20px;
    margin-top: 0px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.device_component_properties {
    color:black;
    font-size: 20px;
    margin-left: 20px;
    margin-bottom: 5px;
}

.gray_image{
    filter:grayscale(100%);
    -webkit-filter: grayscale(100%);

    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
 }

 .selected_image{
    filter:grayscale(100%);
    filter:hue-rotate(0deg);
    -webkit-filter: grayscale(100%);
    -webkit-filter: hue-rotate(0deg);

    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
 } 

 .not_draggable_image{
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
 }
 

/* Device Component */


.symbol_text{
    position: absolute;
    color:white;
    font-size: 9px;
    white-space: nowrap;
    text-shadow: 0 0 1px black, 0 0 1px black, 0 0 4px black;
 }

 /* .marker{
    display: flex;
    align-items: center;
    justify-content: center;
 } */
 .marker{
    position: absolute;
 }

 .marker_icon{
    position: absolute;
    height: 20px;
    left:8px;
    top:7px;
 }

 .input_checkbox {
    height: 20px;
    width: 20px;
    position: absolute;
    accent-color: #E4A83C;
  
}

.dragging {
    background-color: pink;
}

.menu_fade_in {
    opacity: 1;
    transform: translate3d(0, 0, 0); 
    transition: 0.25s all ease-in-out;
}

 
.zoom_in_div {
    width: 100px; 
    height: 100px; 

    transition: width 0.5s ease, height 0.5s ease;

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;

    overflow: hidden;
    overflow-y: none;
    
    
    pointer-events: auto;
    width: auto; 
    height: auto;
    background-color: transparent;   
    
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.3);
    
}
  
.zoom_in_div.visible {
    width: 600px; 
    height: 600px; 
    
}

.zoom_in_div img {
    width: 100%; 
}


  