.listElement {
    margin-bottom: 10px;
    left: 10px;
    width: 400px;
    height: 149px;
    background: #1c1c1c 0% 0% no-repeat padding-box;
    border: 1px solid #323136;
    border-radius: 8px;
    opacity: 1;
    position: relative;
}


.meteoTipStart {
    top: 11px;
    left: 314.25px;
    width: 24px;
    height: 29px;
    text-align: center;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 29px;
    font-family: 'Weather Icons';
    letter-spacing: 0px;
    opacity: 1;
    position: relative;
}

.celsiusStart {
    top: 12px;
    left: 351px;
    width: 20px;
    height: 28px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 28px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    position: absolute;
}

.meteoTip {
    width: 36px;
    height: 46px;
    text-align: center;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 46px;
    font-family: 'Weather Icons';
    letter-spacing: 0px;
    color: #FFFFFF;
    fill: #FFFFFF;
    opacity: 1;
    position: relative;
}

.divCelsius {
    top: 36px;
    left: 69.62px;
    width: 107px;
    height: 63px;
    position: absolute;
}

.valCelsius {
    width: 58px;
    height: 63px;
    text-align: right;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 63px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    position: relative;
}

.lineCelsius {
    top: 10.5px;
    left: 77.13px;
    width: 0px;
    height: 44px;
    border: 1px solid #FFFFFF;
    opacity: 0.5;
    position: absolute;
}

.labelMaxCelsius {
    top: 4.5px;
    left: 88px;
    width: 19px;
    height: 26px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    position: absolute;
}

.labelMinCelsius {
    top: 31.5px;
    left: 88px;
    width: 24px;
    height: 26px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
    font-family: Roboto;
    font-weight: 300;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    position: absolute;
}

.divHumidity {
    top: 28px;
    left: 201px;
    width: 53px;
    height: 22px;
    background: #494949 0% 0% no-repeat padding-box;
    border-radius: 21px;
    opacity: 1;
    position: absolute;
}

.divWind {
    top: 54px;
    left: 201px;
    width: 115px;
    height: 22px;
    background: #494949 0% 0% no-repeat padding-box;
    border-radius: 21px;
    opacity: 1;
    position: absolute;
}

.divPrecipitation {
    top: 80px;
    left: 201px;
    width: 58px;
    height: 22px;
    background: #494949 0% 0% no-repeat padding-box;
    border-radius: 21px;
    opacity: 1;
    position: absolute;
}

.divNen {
    top: 54px;
    left: 321px;
    width: 50px;
    height: 22px;
    background: #494949 0% 0% no-repeat padding-box;
    border-radius: 21px;
    opacity: 1;
    position: absolute;
}

.labelOptions {
    height: 10px;
    text-align: center;
    font-style: normal;
    font-variant: normal;
    font-weight: medium;
    font-size: 10px;
    line-height: 10px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    position: absolute;
}

.IconWeather {
    top: 5px;
    left: 6px;
    text-align: center;
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: 12px;
    font-family: 'Weather Icon';
    letter-spacing: 0px;
    opacity: 10;
    position: absolute;
}


.expandDiv {
    /* top: 200px;
    left: 200px;  */
    height: 360px;
    width: 500px;  

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    /* transform: translate(700px,200px); */
    box-shadow: 0px 20px 40px #00000099;
    border: 1px solid #323136;
    border-radius: 8px;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    pointer-events: auto;
}

/* Expand info*/

.divFilter {
    top: 155.83px;
    left: 10px;
    width: 131px;
    height: 11px;
    opacity: 1;
    position: absolute;
}

.labelTemperatura {
    top: 0.5px;
    height: 10px;
    text-align: center;
    font: normal normal medium 8px/10px Roboto;
    font-style: normal;
    font-variant: normal;
    font-weight: medium;
    font-size: 8px;
    line-height: 10px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    position: absolute;
}

.divBara {
    top: 0px;
    left: 105px;
    width: 0px;
    height: 10px;
    border: 0.5px solid #FFFFFF;
    opacity: 0.5;
    position: absolute;
}

.divClicked {
    top: 168.83px;
    width: 46px;
    height: 2px;
    background: #FEDD6B 0% 0% no-repeat padding-box;
    opacity: 1;
    position: absolute;
}

.divImpartire {
    top: 0;
    left: 10px;
    width: 480px;
    height: 0px;
    border: 1px solid #323136;
    opacity: 1;
    position: absolute;
}

.divDays {
    top: 260.06px;
    width: 500px;
    height: 100px;
    opacity: 1;
    position: absolute;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.divDay {
    top: 10px;
    margin-left: 10px;
    width: 60px;
    height: 80px;
    /* background: #1a1919 0% 0% no-repeat padding-box; */
    border-radius: 8px;
    position: relative;
}

.background_select_day {
    top: 13px;
    margin-left: 10px;
    width: 60px;
    height: 80px;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 8px;
    position: absolute;
    -webkit-transition: left 0.3s ease-in-out;
       -moz-transition: left 0.3s ease-in-out;
        -ms-transition: left 0.3s ease-in-out;
         -o-transition: left 0.3s ease-in-out;
            transition: left 0.3s ease-in-out;
}

.shortDay {
    top: 7px;
    left: 20px;
    width: 20px;
    height: 19px;
    text-align: center;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 29px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    position: absolute;
}

.iconWeek {
    top: 33px;
    left: 21px;
    width: 18px;
    height: 24px;
    text-align: center;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Weather Icons';
    letter-spacing: 0px;
    opacity: 1;
    position: absolute;
}

.celsiusWeek {
    top: 66px;
    width: 14px;
    height: 10px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 10px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.7;
    position: absolute;
}

.imageAnimation {
    top: 21px;
    left: 340px;
    width: 150px;
    height: 80px;
    background: transparent 0% 0% no-repeat padding-box;
    mix-blend-mode: screen;
    opacity: 1;
    position: absolute;
}

.divDate {
    top: 79.83px;
    left: 398.75px;
    width: 83px;
    height: 41px;
    opacity: 1;
    position: absolute;
}

.expandDate {
    top: 0px;
    left: 43px;
    width: 40px;
    height: 10px;
    text-align: right;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 10px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.7;
    position: absolute;
}

.expandDayHour {
    top: 10px;
    left: 0px;
    width: 83px;
    height: 21px;
    text-align: right;
    font-style: normal;
    font-variant: normal;
    font-weight: medium;
    font-size: 16px;
    line-height: 21px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    position: absolute;
}

.weatherType {
    top: 31px;
    left: 61px;
    width: 22px;
    height: 10px;
    text-align: right;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 10px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.7;
    position: absolute;
}

.grafic {
    color: var(---ffffff);
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 11px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.expand_inner_shadow {
    top: 0px;
    left: 0px;
    position: absolute;
    width: 500px;
    height: 360px;
    border-radius: 10px;
    background: transparent linear-gradient(180deg, #000000 60%, rgba(0, 0, 0, 0.7) 70%, rgba(0, 0, 0, 0.5) 80%, rgba(0, 0, 0, 0.3) 90%, transparent 100%) 0% 0% no-repeat padding-box;
}

.image_expand {
    top: 58.06px;
    left: 336.09px;
    width: 150px;
    height: 80px;
    background: transparent 0% 0% no-repeat padding-box;
    mix-blend-mode: screen;
    position: absolute;
}