
.menu_bgr_blur {
	overflow: visible;
	position: absolute;
	top: 0px;
    left: 0px;
    width: 594px;
    height: 424px;
    
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    
    -webkit-mask-image: url("../graphics/layout/mainmenu_bgr_blur.svg");
    mask-image: url("../graphics/layout/mainmenu_bgr_blur.svg");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;     

	background-color: rgba(2,1,7,0.3);

    box-shadow: 0px 10px 20px #00000080;
}

.menu_bgr_cerc {
	overflow: visible;
	position: absolute;
	top: 0px;
    left: 0px;
    width: 440px;
    height: 440px;
    
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    
    -webkit-mask-image: url("../graphics/layout/mm-bgr-cerc.svg");
    mask-image: url("../graphics/layout/mm-bgr-cerc.svg");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;     

	background-color: rgba(2,1,7,0.3);

    box-shadow: 0px 10px 20px #00000080;
    border-radius: 50%;

    -webkit-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
       -moz-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
        -ms-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
         -o-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
            transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
}

.menu_bgr_cerc_interior {
	overflow: visible;
	position: absolute;
	top: 89px;
    left: 89px;
    width: 262px;
    height: 262px;
    
    -webkit-mask-image: url("../graphics/layout/mm-cerc-interior.svg");
    mask-image: url("../graphics/layout/mm-cerc-interior.svg");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;     

	background-color: black;
    border-radius: 50%;

    -webkit-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
       -moz-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
        -ms-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
         -o-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
            transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
}
.menu_bgr_cerc_outline {
	overflow: visible;
	position: absolute;
	top: 49px;
    left: 49px;
    width: 342px;
    height: 342px;
    
    -webkit-mask-image: url("../graphics/layout/mm-cerc-3-outline.svg");
    mask-image: url("../graphics/layout/mm-cerc-3-outline.svg");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;     

	/* background-color: #e8e8e8; */
}
.menu_bgr_cerc_4 {
	overflow: visible;
	position: absolute;
	top: 72px;
    left: 72px;
    width: 296px;
    height: 296px;
    
    -webkit-mask-image: url("../graphics/layout/mm-cerc-1.svg");
    mask-image: url("../graphics/layout/mm-cerc-1.svg");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;     

	background-color: black;
    opacity: 0.5;
    border-radius: 50%;
    -webkit-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
       -moz-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
        -ms-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
         -o-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
            transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
}

.menu_cerc_outline{
    -webkit-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
       -moz-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
        -ms-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
         -o-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
            transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
    transform-origin: 50% 50%;
}

.menu_bgr_bkg {
	overflow: visible;
	position: absolute;
	top: 30px;
    left: 32px;
    width: 530px;
    height: 360px;   

    -webkit-mask-image: url("../graphics/layout/mainmenu_bgr_bkg.svg");
    mask-image: url("../graphics/layout/mainmenu_bgr_bkg.svg");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;  

	background-color: black;
}

.menu_button {
    fill: url(#normal_gradient);
    /* fill: red; */
}

.menu_button_selected {
    fill: url(#selected_gradient);
    /* fill: red; */
}

.scrollmenu {
    -webkit-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
       -moz-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
        -ms-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
         -o-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
            transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
    width: 130px;
}

.scrollmenu_no_overflow {
    -webkit-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
       -moz-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
        -ms-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
         -o-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
            transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
    width: 130px;
}

.scrollmenu div {
    /* overflow: hidden; */
    width: 100%;
    position: relative;
    text-align: right;
    -moz-transform: translateX(10%);
    -webkit-transform: translateX(10%);
    transform: translateX(10%);
    
    -moz-animation: my-animation 10s linear infinite;
    -webkit-animation: my-animation 10s linear infinite;
    animation: my-animation 10s linear infinite;
}
  
@-moz-keyframes my-animation {
    0% { -moz-transform: translateX(10%); }
    50% { -moz-transform: translateX(-100vw); }
    100% { -moz-transform: translateX(10%); }
}
  
@-webkit-keyframes my-animation {
    0% { -webkit-transform: translateX(10%); }
    50% { -webkit-transform: translateX(-100vw); }
    100% { -webkit-transform: translateX(10%); }
}
  
@keyframes my-animation {
    0% {transform: translateX(10%);}
    50% {transform: translateX(var(--clientWidth));}
    100% {transform: translateX(10%);}
}


.logout_confirmation{
    display: flex;
    justify-content: center;
    align-items: center;

    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    -ms-transition: width 0.3s;
    -o-transition: width 0.3s;
    transition: width 0.3s;
    
    left: 50%;
    transform: translate(-50%, 0);
}

.bttns_transition {
    -webkit-transition: top 0.5s ease-out, left 0.5s ease-out, opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
       -moz-transition: top 0.5s ease-out, left 0.5s ease-out, opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
        -ms-transition: top 0.5s ease-out, left 0.5s ease-out, opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
         -o-transition: top 0.5s ease-out, left 0.5s ease-out, opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
            transition: top 0.5s ease-out, left 0.5s ease-out, opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
}

.bttns_transition_anim {
    -webkit-transition: top 0.5s ease-out, left 0.5s ease-out, opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
       -moz-transition: top 0.5s ease-out, left 0.5s ease-out, opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
        -ms-transition: top 0.5s ease-out, left 0.5s ease-out, opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
         -o-transition: top 0.5s ease-out, left 0.5s ease-out, opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
            transition: top 0.5s ease-out, left 0.5s ease-out, opacity 0.25s ease-in-out, transform 0.25s ease-in-out;

    pointer-events: none;
}
.secundar_transition {
    -webkit-transition: top 0.5s ease-out, left 0.5s ease-out, width 0.5s ease-out, height 0.5s ease-out;
       -moz-transition: top 0.5s ease-out, left 0.5s ease-out, width 0.5s ease-out, height 0.5s ease-out;
        -ms-transition: top 0.5s ease-out, left 0.5s ease-out, width 0.5s ease-out, height 0.5s ease-out;
         -o-transition: top 0.5s ease-out, left 0.5s ease-out, width 0.5s ease-out, height 0.5s ease-out;
            transition: top 0.5s ease-out, left 0.5s ease-out, width 0.5s ease-out, height 0.5s ease-out;
}
.centru_meniu_transition {
    -webkit-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
       -moz-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
        -ms-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
         -o-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
            transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out;
}

.main_menu_touch_component {
    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    
    /* background-color: azure; */
    pointer-events: auto;
    width: auto; 
    height: auto;
}

.main_menu_touch_component_anim {
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s;

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    
    
    pointer-events: auto;
    width: auto; 
    height: auto;
}

.rotate_dreapta {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
}

.scrollmenu_dreapta {
    -webkit-transition: all 0.5s ease-out;
       -moz-transition: all 0.5s ease-out;
        -ms-transition: all 0.5s ease-out;
         -o-transition: all 0.5s ease-out;
            transition: all 0.5s ease-out;
    width: 130px;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
}

.scrollmenu_no_overflow_dreapta {
    -webkit-transition: all 0.5s ease-out;
       -moz-transition: all 0.5s ease-out;
        -ms-transition: all 0.5s ease-out;
         -o-transition: all 0.5s ease-out;
            transition: all 0.5s ease-out;
    width: 130px;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
}

.scrollmenu_dreapta div {
    /* overflow: hidden; */
    width: 100%;
    position: relative;
    text-align: right;
    -moz-transform: translateX(10%);
    -webkit-transform: translateX(10%);
    transform: translateX(10%);

    
    
    -moz-animation: my-animation 10s linear infinite;
    -webkit-animation: my-animation 10s linear infinite;
    animation: my-animation 10s linear infinite;
}

.scrollmenu_stanga {
    -webkit-transition: all 0.5s ease-out;
       -moz-transition: all 0.5s ease-out;
        -ms-transition: all 0.5s ease-out;
         -o-transition: all 0.5s ease-out;
            transition: all 0.5s ease-out;
    width: 130px;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}

.scrollmenu_no_overflow_stanga {
    -webkit-transition: all 0.5s ease-out;
       -moz-transition: all 0.5s ease-out;
        -ms-transition: all 0.5s ease-out;
         -o-transition: all 0.5s ease-out;
            transition: all 0.5s ease-out;
    width: 130px;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}

.scrollmenu_stanga div {
    /* overflow: hidden; */
    width: 100%;
    position: relative;
    text-align: right;
    -moz-transform: translateX(10%);
    -webkit-transform: translateX(10%);
    transform: translateX(10%);

    
    
    -moz-animation: my-animation 10s linear infinite;
    -webkit-animation: my-animation 10s linear infinite;
    animation: my-animation 10s linear infinite;
}

.sus {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}

.menu_fade_out_dreapta {
    opacity: 0;
    transform: translate3d(104px, 0, 0); 
    transition: 0.25s all ease-in-out;
}
.menu_fade_out_stanga {
    opacity: 0;
    transform: translate3d(-104px, 0, 0); 
    transition: 0.25s all ease-in-out;
}

.menu_fade_out_sus {
    opacity: 0;
    transform: translate3d(0, -104px, 0); 
    transition: 0.25s all ease-in-out;
}
.menu_fade_out_jos {
    opacity: 0;
    transform: translate3d(0, 104px, 0); 
    transition: 0.25s all ease-in-out;
}

.project_name_scroller {
    overflow: hidden;
}
  
.project_name {
    white-space: nowrap;
    display: inline-block;
}

