.search_sites_component {
    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    flex-direction: column;
    pointer-events: auto;

    padding:40px;
    
    background-color: rgba(2,1,7,0.3);

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    border-radius: 10px;

    box-shadow: 0px 10px 20px #00000080;
}

.search_sites_component_anim {
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s;


    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    flex-direction: column;
    pointer-events: auto;

    padding:20px;
    
    background-color: rgba(2,1,7,0.3);

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    border-radius: 10px;

    box-shadow: 0px 10px 20px #00000080;
}

@keyframes smooth-appear-sites {
    100% {
        left: 830px;
        opacity: 1;
    }
}

.search_sites_component_animation {
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    flex-direction: column;
    pointer-events: auto;

    position: fixed;
    left: -100%;
    opacity: 0;
    /* transform: translateX(-50%); */
    animation: smooth-appear-sites 1s ease forwards;

    padding:40px;
    
    background-color: rgba(2,1,7,0.3);

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    border-radius: 10px;

    box-shadow: 0px 10px 20px #00000080;
}

.search_result_button {
    
    vertical-align: middle;
    height: 35px;
    margin: auto;
    border-bottom: 1px solid rgb(78, 77, 77);
    padding: 10px;
    margin-top: 5px;

    color:white;

    font-size: 20px;

    -webkit-transition: background-color 1s;
    -moz-transition: background-color 1s;
    -ms-transition: background-color 1s;
    -o-transition: background-color 1s;
    transition: background-color 1s;
}

.search_result_button_classic {
    background-color: #18181C;
    border-radius: 5px;
    
    vertical-align: middle;
    height: 35px;
    margin: auto;
    padding: 10px;
    margin-top: 5px;

    color:white;

    font-size: 24px;

    -webkit-transition: background-color 1s;
    -moz-transition: background-color 1s;
    -ms-transition: background-color 1s;
    -o-transition: background-color 1s;
    transition: background-color 1s;
}

.search_result_button_clicked {
    background-color: #18181C;
    border-radius: 5px;
    border: 1px solid #E4A83C;
    
    vertical-align: middle;
    height: 35px;
    margin: auto;
    padding: 10px;
    margin-top: 5px;

    color:white;

    font-size: 24px;

    -webkit-transition: background-color 1s;
    -moz-transition: background-color 1s;
    -ms-transition: background-color 1s;
    -o-transition: background-color 1s;
    transition: background-color 1s;
}

.load_project_button_clicked{
    position: absolute;
    background-color: #383838;
    /* box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.3); */
    color: #E4A83C;

    width: 140px;
    height: 45px;
    border-radius: 10px;
    border: 1px solid #434246;

    font-size: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    cursor: grab;
}

.search_result_button_last {
    
    vertical-align: middle;
    height: 35px;
    margin: auto;
    padding: 10px;
    margin-top: 5px;

    color:white;

    font-size: 20px;

    -webkit-transition: background-color 1s;
    -moz-transition: background-color 1s;
    -ms-transition: background-color 1s;
    -o-transition: background-color 1s;
    transition: background-color 1s;
}
