.devices_component {
    border-radius: 20px 20px 20px 20px;
    /* -webkit-transition: height 0.5s;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
    transition: height 0.5s; */

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */

    background-color: rgba(0, 0, 0, 0.3);

    pointer-events: auto;
}

.devices_component_anim {
    border-radius: 20px 15px 20px 25px;
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s;

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.3);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */


    

    pointer-events: auto;
}

.device_component {
    /* -webkit-transition: height 0.5s ease-in, width 0.5s ease-in;
    -moz-transition: height 0.5s ease-in, width 0.5s ease-in;
    -ms-transition: height 0.5s ease-in, width 0.5s ease-in;
    -o-transition: height 0.5s ease-in, width 0.5s ease-in;
    transition:  height 0.5s ease-in, width 0.5s ease-in; */
    

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    border-radius: 20px;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 955px;
    height: 100px;

    background-color: rgba(0, 0, 0, 0.3);

    pointer-events: auto;    
    
    color:white;
    font-size: 30px;

    display: flex;

    padding: 20px;
    overflow: visible;
}

.device_component_anim {
    -webkit-transition: height 0.5s ease-in, width 0.5s ease-in;
    -moz-transition: height 0.5s ease-in, width 0.5s ease-in;
    -ms-transition: height 0.5s ease-in, width 0.5s ease-in;
    -o-transition: height 0.5s ease-in, width 0.5s ease-in;
    transition:  height 0.5s ease-in, width 0.5s ease-in;

    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s;
    

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    border-radius: 20px;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 955px;
    height: 100px;

    background-color: rgba(0, 0, 0, 0.3);

    pointer-events: auto;    
    
    color:white;
    font-size: 30px;

    display: flex;
    padding: 20px;

    overflow: visible;
}
.device_component_back{
    background-color: black;

    font-size: 30px;
    flex-direction: column;
    display: flex;
}
.device_component_title {
    color:white;
    font-size: 30px;
    margin: 0 auto;
}

.device_component_subtitle {
    color:white;
    font-size: 30px;
    margin-top: 0px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.device_component_properties {
    color:white;
    font-size: 20px;
    margin-left: 20px;
    margin-bottom: 5px;
}

.drone_component_title {
    text-align: left;
    font-size: 23px;
    letter-spacing: 0px;
    color: #FFFFFF;
    padding-left: 27px;
    padding-top: 19px;
}

.drone_component_subtitle {
    text-align: left;
    letter-spacing: 0px;
    color: #A9A9AB;
    padding-left: 27px;
    font-size: 18px;
}
.live{
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 4px;
    text-align: center;
    letter-spacing: 0px;
    color: #12E5DD;
    text-transform: uppercase;
    position: absolute;
    top: 22px;
    left: 27px;
    width: 41px;
    height: 15px;
    font-size: 10px;
}

.drone_component_back{
    background-color: #1C1C1C;

    font-size: 30px;
    flex-direction: column;
    display: flex;
    border: 1px solid #323136;
    border-radius: 8px;

    overflow: visible;
}

.butoane_drona {
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    top: 18px;
    right: 18px;
}

.history_drona {
    width: 80px;
    height: 56px; 
    background-color: #121212; 
    margin-right: 2px;
    /* display: flex;
    flex-direction: row; */
}
.background_select_history{
    top: 0px;
    width: 80px;
    height: 56px;
    background: transparent linear-gradient(180deg, #18BEDD 0%, #9ED688 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    position: absolute;
    -webkit-transition: left 0.3s ease-in-out;
       -moz-transition: left 0.3s ease-in-out;
        -ms-transition: left 0.3s ease-in-out;
         -o-transition: left 0.3s ease-in-out;
            transition: left 0.3s ease-in-out;
    
}

.live_mare{
    background-color: transparent;
    border-radius: 8px;
    text-align: center;
    letter-spacing: 0px;
    color: black;
    text-transform: uppercase;
    position: absolute;
    top: 15px;
    left: -2px;
    width: 80px;
    height: 56px;
    font-size: 23px;
}

.drone_header{
    position: relative;
    height: 90px;
    width: 100%;
    border-bottom: 1px solid #383838;
    padding-bottom: 24px;
    margin-bottom: 1px;
    /* opacity: 0.4; */
}

.drona_specs {
    display: flex;
    flex-direction: row;
    height: 75px;
    margin-top: 44px;
}

.drona_specs_category {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #A9A9AB;
    text-align: center;
    width: 266px;
}

.drona_specs_value {
    width: 238px;
    height: 24px;
    background: #18181C 0% 0% no-repeat padding-box;
    border: 1px solid #434246;
    border-radius: 32px;
    color: #E2E2E2;
    text-align: center;
    font-size: 15px;
    padding-top: 7px;
    margin-top: 15px;
    margin-left: 13px;
}

/* .drone_component {
    -webkit-transition: height 0.5s ease-in, width 0.5s ease-in;
    -moz-transition: height 0.5s ease-in, width 0.5s ease-in;
    -ms-transition: height 0.5s ease-in, width 0.5s ease-in;
    -o-transition: height 0.5s ease-in, width 0.5s ease-in;
    transition:  height 0.5s ease-in, width 0.5s ease-in;
    

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    border-radius: 20px;

    width: 955px;
    height: 100px;

    background-color: rgba(0, 0, 0, 0.3);

    pointer-events: auto;    
    
    color:white;
    font-size: 30px;

    display: flex;

    padding: 20px;
    overflow: visible;
} */

.div_common {
    touch-action: none;
    user-select: none;
    pointer-events: auto;    
    width: 283px;
    height: 391px;
    opacity: 1;
    background-color: rgb(12, 11, 11);
    border-radius: 8px;
}

.labelType {
    top: 22px;
    left: 101.5px;
    height: 16px;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    font-family: Roboto;
    letter-spacing: 0;
    color: #A9A9AB;
    text-align: center;
    opacity: 1;
    position: absolute;
}

.labelAlert {
    top: 47px;
    left: 67.5px;
    /* height: 43px; */
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 20px;
    font-family: Roboto;
    letter-spacing: 0;
    color: #FFFFFF;
    text-align: center;
    opacity: 1;
    position: absolute;

}

.labelAlertLocation {
    top: 81px;
    left: 45.17px;
    width: 191px;
    height: 16px;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    font-family: Roboto;
    letter-spacing: 0;
    color: #A9A9AB;
    text-align: center;
    opacity: 1;
    position: absolute;
}

.positionAlert {
    top: 101px;
    left: 22.52px;
    width: 238px;
    height: 31px;
    border: 1px solid #434246;
    background: #18181C 0% 0% no-repeat padding-box;
    border-radius: 32px;
    opacity: 1;
    position: absolute;
}

/* Layout and UI Properties */
.posLat {
    top: 5.12px;
    left: 16.63px;
    width: 88px;
    height: 20px;
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    font-family: Roboto Condensed;
    letter-spacing: 0px;
    color: #E2E2E2;
    text-align: center;
    opacity: 1;
    position: absolute;
}

.posLong {
    top: 5.12px;
    left: 137px;
    width: 88px;
    height: 20px;
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    font-family: Roboto Condensed;
    letter-spacing: 0px;
    color: #E2E2E2;
    text-align: center;
    opacity: 1;
    position: absolute;
}

.videoAnimationAlert {
    top: 137.7px;
    left: 81.5px;
    width: 120px;
    height: 120px;
    opacity: 1;
    position: absolute;
}

.detectedLabel {
    top: 262.7px;
    left: 93.5px;
    height: 16px;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #A9A9AB;
    text-align: center;
    opacity: 1;
    position: absolute;
}

.yes_no_label {
    bottom: 80.37px;
    left: 100.5px;
    width: 88px;
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    font-size: 24px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #EC4828; 
    background-color: transparent;
    text-align: center;
    opacity: 1;
    position: absolute;
}

.buttonAlert {
    top: 340px;
    left: 70.5px;
    width: 144px;
    height: 30px;
    background: transparent linear-gradient(91deg, #818181 0%, #6C6C6C 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    position: absolute;
}

.labelButtonAlert {
    top: 6px;
    left: 26px;
    width: 92px;
    height: 21px;
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-align: center;
    text-transform: uppercase; 
    opacity: 1;
    position: absolute;
}