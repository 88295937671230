/* Login Component */
.login_component {
    overflow: hidden;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    pointer-events: auto;
}

.login_iframe {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    
    /* transform: translate(-50%, -50%) scale(0.8, 0.8); */
    
    border: 0px solid;
}

.sites_component {
    width: vw;
    height: vh;
    border-radius: 20px;
    border: 0px solid;
    font-size: 20px;
    padding: 10px
}

.site_button {
    height: 30px;
    border-bottom: 1px solid gainsboro;
    width: 90vw;
    text-align: center;
    padding: 10px;

  
    -webkit-transition: background-color 1s;
    -moz-transition: background-color 1s;
    -ms-transition: background-color 1s;
    -o-transition: background-color 1s;
    transition: background-color 1s;
}

.site_button_clicked {
    background-color: gainsboro;
    height: 30px;
    border-bottom: 1px solid gainsboro;
    width: 90vw;
    text-align: center;
    padding: 10px;

    -webkit-transition: background-color 1s;
    -moz-transition: background-color 1s;
    -ms-transition: background-color 1s;
    -o-transition: background-color 1s;
    transition: background-color 1s;
}

.site_confirm {
    height: 40px;
    width: 20vw;
    text-align: center;
    padding: 10px;
    margin-top: 20px;    
}
/* Login Component */

/* Project component */
.project_component {



    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 100vw;
    height: 100vh;    

    pointer-events: auto;
}

.projects_list_component {
    width: 100vw;
    height: auto;   
    overflow-y:auto;    
    border: 0px solid;
    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    font-size: 20px;
    margin-left: 10px;
}

.invite_component {
    -webkit-transition: height 0.5s;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
    transition: height 0.5s;


    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    padding: 10px;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: auto;

    background-color: rgba(61, 165, 56, 0.7);

    pointer-events: auto;

    vertical-align: middle;
    margin: auto;
    color:white;
    font-size: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.load_project_button{
    
    width: 100px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid black;
    margin-top: 10px;

    font-size: 20px;
    color: black;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    cursor: grab;
    left: calc(100vw - 100);
}

.back_button{
    position: absolute;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid white;
    margin: 20px;

    font-size: 20px;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    cursor: grab;
    left: calc(100vw - 100);
}


/* Project component */


.video_component {
    will-change: transform;
    width: 40vw;
    height: 200px;
    margin-top: 70px;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    border-radius: 5px;
    background-color: black;
    pointer-events: auto;
    margin-left: 5vw;
}



.my_video_component {
    position: absolute;
    will-change: transform;
    width: 40vw;
    height: 150px;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    
    background-color: black;
    pointer-events: auto;
    right: 10px;
    bottom: 10px;
    z-index: 999;
}
