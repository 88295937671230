.symbols_component {
    backdrop-filter: blur(12.7px);
    -webkit-backdrop-filter: blur(12.7px);
    

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    border-radius: 10px;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 880px;
    height: 200px;

    background-color: rgba(2,1,7,0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    pointer-events: auto;

    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.symbols_component_anim {
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s;

    background-color: rgba(2,1,7,0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    flex-direction: column;
    border-radius: 10px;

    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    width: 880px;
    height: 200px;


    pointer-events: auto;
}

.projects_list_component {
    width: 940px;
    height: 400px;
    position: absolute;

    overflow-y:auto;

    border-radius: 5px;
    border: 0px solid;
    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    background-color: aliceblue;
    font-size: 20px;

}

.color_button_clicked {
    position: absolute;
    
    /* box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.3); */

    width: 80px;
    height: 80px;
    border-radius: 5px;
    border-color: gray;
    border-style: solid;
    

    display: flex;
    justify-content: center;
    align-items: center;
}

.color_button {
    position: absolute;
    
    /* box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.3); */

    width: 80px;
    height: 80px;
    border-radius: 5px;
    border-style: solid;
    border-color: transparent;

    display: flex;
    justify-content: center;
    align-items: center;
}

.not_draggable_image{
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
 }