.input_number {
    text-align: center;
    color: white;
    font-size: 32px;
    height: 50px;
    margin: 5px;
    /* background-color: red; */
    margin-top: 10px;
    /* border-bottom: 1px solid gray; */
    width: 255px;
}

.key_button {
    text-align: center;
    vertical-align: middle;
    width: 90px;
    height: 40px;
    padding-top: 10px;
    border-radius: 5px;
    font-size: 20px;

    vertical-align: middle;
    background-color: #404040;

    margin: 100px;
    color: #E2E2E2;
}

.lottie_dialer_button {
    margin: 5px;
    width: 64px;
    height: 64px;
    border: solid;
    border-radius: 100%;
    opacity: 1;
    border-width: 1px;
    border-color: #404040;
    vertical-align: middle;
    position: relative;
}

.lottie_x_button {
    margin: 5px;
    width: 33px;
    height: 33px;
    border: solid;
    border-radius: 50%;
    border-width: 1px;
    border-color: #404040;
    text-align: center;
    position: relative;
    color: #C3C3C3;
}



.lottie_dialer_text {
    top: 25%;
    left: 40%;
    position: absolute;
    
    font: normal normal 300 22px/29px Roboto;
    letter-spacing: 0px;
    color: #E2E2E2;
    opacity: 1;
}

.lottie_text_input {
    width: 98%;
    background: black;
    height: 44px;
    border: 1px solid #323136; &:focus {outline: none; border-color: #41cdcd;};
    color: #AAAAAA; &:focus {outline: none; color: #41cdcd;};
    font-size: 15px;
    border-radius: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.lottie_text_placeholder {
    pointer-events: none;
    background: black;
    position: absolute;
    top: 30%;
    left: 5%;
    color: #AAAAAA; 
    -webkit-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out, color 0.25s ease-in-out, font-size 0.25s ease-in-out;
       -moz-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out, color 0.25s ease-in-out, font-size 0.25s ease-in-out;
        -ms-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out, color 0.25s ease-in-out, font-size 0.25s ease-in-out;
         -o-transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out, color 0.25s ease-in-out, font-size 0.25s ease-in-out;
            transition: transform 0.5s ease-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out, top 0.25s ease-in-out, color 0.25s ease-in-out, font-size 0.25s ease-in-out;

}

.call_button {
    text-align: center;
    vertical-align: middle;
    width: 255px;
    height: 43px;
    padding-top: 10px;
    font-size: 20px;

    vertical-align: middle;
    background-color: #383838;

    box-shadow: 0px 8px 16px #5BCAB200;
    border-radius: 8px;

    margin: 5px;
    color: #878688;
    opacity: 0.5;
}

.in_call_div {
    position: absolute;
    left: 0;
    top: 0;

    background-color: black;
    color: white;
    width: 100vw;
    height: 100vh;

    font-size: 30px;
    vertical-align: middle;


}


.sip_component {
    border-radius: 10px;

    background-color: transparent;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;


    /* padding: 20px;

    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px; */

    pointer-events: auto;
}

.sip_component_background {
    background-color: rgb(0, 0, 0);
    border-radius: 8px;
    /* width: calc(100% - 80px);
    height: calc(100% - 80px); */
    padding: 40px;
}

.tabmenu_background {
    width: 288px;
    height: 40px;
    border: 1px solid #707070;
    border-radius: 8px;
    background-color: transparent;
}

.buttons_table {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: transparent;
    position: relative;
    top: -40px;
}

.marker {
    -webkit-transition: left 0.3s;
    -moz-transition: left 0.3s;
    -ms-transition: left 0.3s;
    -o-transition: left 0.3s;
    transition: left 0.3s;

    position: relative;
    width: 96px;
    height: 40px;
    border-radius: 8px;
    background-color: #E2E2E2;
    left: 0px;
}

.in_call_div {
    position: absolute;
    left: 0;
    top: 0;

    background-color: black;
    color: white;
    width: 100vw;
    height: 100vh;

    font-size: 30px;
    vertical-align: middle;


}

.calling_div {
    position: absolute;
    /* background-color: rgba(12, 12, 12, 0.75);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px); */
    background: #000000 0% 0% no-repeat padding-box;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    left: 0px;
    top: 0px;
    border-radius: 8px;
    width: 100%;
    height: 100%;;
    text-align: center;
    /* padding-top: 20px; */
}


.stop_button {
    position: absolute;
    left:0px;
    bottom:0px;
    width: 100%;
    height: 43px;
    background: transparent linear-gradient(90deg, #DD0000 0%, #EF5731 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #E0250080;
    border-radius: 8px;
    padding-top: 10px;
}

.background_green_call {
    width: 140px;
    height: 140px;
    background: transparent linear-gradient(44deg, #18BEDD 0%, #9ED688 100%) 0% 0% no-repeat padding-box;
    opacity: 0.8;
    filter: blur(38px);
    border-radius: 50%;
}

.background_green_call_incall {
    width: 104px;
    height: 104px;
    background: transparent linear-gradient(44deg, #18BEDD 0%, #9ED688 100%) 0% 0% no-repeat padding-box;
    opacity: 0.8;
    filter: blur(30px);
    border-radius: 50%;
}

.background_red_reject {
    width: 88px;
    height: 88px;
    background: transparent linear-gradient(0deg, #DD0000 0%, #DE0503 0%, #EF5731 100%) 0% 0% no-repeat padding-box;
    filter: blur(38px);
    opacity: 0.8;
}
.background_green_answer {
    width: 88px;
    height: 88px;
    background: transparent linear-gradient(44deg, #18BEDD 0%, #9ED688 100%) 0% 0% no-repeat padding-box;
    filter: blur(38px);
    opacity: 0.8;
}


.speaking_div {
    position: absolute;
    background-color: rgba(12, 12, 12, 0.75);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    left: 20px;
    top: 20px;
    border-radius: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 60px);
    text-align: center;
    padding-top: 20px;
}

.answerCircle {
    position: absolute;
    width: 53px;
    height: 30px;

    left: 159px;
    border-radius: 25px 25px 0% 0%;
    top: 150px;

    background-image: linear-gradient(to bottom, #7c9b8f, #7c9b8f);


}

.answerCircle2 {
    position: absolute;
    width: 53px;
    height: 90px;

    left: 159px;

    top: 179px;

    background-image: linear-gradient(to bottom, #7c9b8f, transparent);
    border: 0px solid transparent;

}

.rejectCircle {
    position: absolute;
    width: 53px;
    height: 30px;

    left: 159px;
    border-radius: 0% 0% 25px 25px;
    top: 470px;

    background-image: linear-gradient(to top, #9e4a4a, #9e4a4a);
}

.rejectCircle2 {
    position: absolute;
    width: 53px;
    height: 90px;

    left: 159px;

    top: 381px;

    background-image: linear-gradient(to top, #9e4a4a, transparent);
    border: 0px solid transparent;

}

.add_button {
    text-align: center;
    vertical-align: middle;
    width: 288px;
    height: 60px;
    padding-top: 10px;
    border-radius: 5px;
    font-size: 20px;

    vertical-align: middle;
    background-color: #555555;

    margin-top: 5px;
    color: #E2E2E2;
}

.call_buttons_div {
    -webkit-transition: right 0.3s;
    -moz-transition: right 0.3s;
    -ms-transition: right 0.3s;
    -o-transition: right 0.3s;
    transition: right 0.3s;
}

.contact_element{
    /* top: 169px;
    left: 9px; */
    width: 300px;
    height: 47px;
    background: #1C1C1C 0% 0% no-repeat padding-box;
    border: 1px solid #323136;
    border-radius: 8px;
    padding-top: 8.8px;
}

.contact_element_details {
    width: 280px;
    height: 231px;
    background: #1C1C1C 0% 0% no-repeat padding-box;
    border: 1px solid #19BEDB;
    border-radius: 8px;
}

.contact_name_details {
    position:absolute;
    top: 2px;
    left: 2px;
    width: 298px;
    height: 40px;
    background: transparent linear-gradient(91deg, #18BEDD 0%, #9ED688 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 0px 0px;
}



.btns_call{
    top: 20px;
    left: 227px;
    width: 71px;
    height: 17.5px;
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
}
.call_bttn {
    width: 17.5px;
    height: 17.5px;
    position: relative;
    margin-left: 18px;
}
.numbers {
    top: 0px;
    left: 0px;
    position: relative;
    display: flex;
    flex-direction: row;
}
.numbers_details {
    transform: matrix(1, 0, 0, 1, 0, 15);
    top: 0px;
    left: 0px;
    position: relative;
    display: flex;
    flex-direction: column;
}
.call_bttn_details {
    width: 17.5px;
    height: 17.5px;
    position: absolute;
    right: 20px;
}

.lista_contacte{
    width: 330px;
    height: 400px;
    overflow-y: hidden;
}

.lista_contacte::-webkit-scrollbar {
    display: none;
}

.lista_contacte_history{
    height: 78px;
    width: 612px;
    overflow: hidden;
}

.lista_contacte_history::-webkit-scrollbar {
    display: none;
}

.sterge_background {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}

.sterge{
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%,-50%);
    width: 198px;
    height: 261px;
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 40px #00000080;
    border-radius: 8px;
}

.sterge_content{
    width: 100%;
    position: relative;
    top: 50px;
    color: white;
    font-size: 15px;
    text-align: center;
}
.sterge_bttn{
    width: 162px;
    height: 33px;
    background: #1C1C1C 0% 0% no-repeat padding-box;
    border: 1px solid #323136;
    border-radius: 8px;
    position: relative;
    margin-top: 9px;
    color: white;
    font-size: 13px;
}

.sip_call_history {
    top: 0px;
    left: 0px;
    width: 612px;
    height: 78px;
    background-color: transparent;
    position: absolute;
    font-size: 12px;
    color: #8D8D8D;
    padding: 5px;
    transform: scale(0.93);
    transform-origin: top left;
    border-bottom: 1px solid #323136;
    /* overflow: hidden; */
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    pointer-events: auto;
}
.sip_call_history::-webkit-scrollbar {
    /* display: none; */
}

.sip_call_history_div {
    top: 0px;
    left: 5px;
    height: 78px;
    width: 612px;
    background-color: transparent;
    position: absolute;
    overflow: hidden;
    /* display: flex;
    flex-direction: row-reverse; */
}



.contact_history {
    width: 348px;
    height: 54px;
    background: #1C1C1C 0% 0% no-repeat padding-box;
    border: 1px solid #323136;
    border-radius: 8px;
    margin-top: 24px;
    margin-left: 8px;
    display: flex;
    flex-direction: row;
}

.call_time{
    width: 150px;
    height: 28.5px;
    position: relative;
    top: 50%;
    transform: translate(0,-50%);
    text-align: right;
    font-size: 11px;
    letter-spacing: 0px;
    color: #A9A9AB;
    padding-right: 10px;
    border-right: 2px solid #A9A9AB;
}

.umbra_dreapta {
    top: 5px;
    right: 0px;
    position: absolute;
    width: 15px;
    height: 80px;
    background: transparent linear-gradient(90deg, #00000000 0%, black 100%) 0% 0% no-repeat padding-box;
}
.umbra_stanga {
    top: 20px;
    left: 0px;
    position: absolute;
    width: 15px;
    height: 65px;
    background: transparent linear-gradient(-90deg, #00000000 0%, black 100%) 0% 0% no-repeat padding-box;
}

.umbra_contacte_jos{
    position: absolute;
    bottom: 48px;
    left: 0px;
    width: 100%;
    height: 44px;
    background: transparent linear-gradient(180deg, #00000000 0%, #0000000A 1%, #000000 100%) 0% 0% no-repeat padding-box;
}

.umbra_contacte_sus{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 20px;
    background: transparent linear-gradient(0deg, #00000000 0%, #0000000A 1%, #000000 100%) 0% 0% no-repeat padding-box;
}

.buton_call_sunet_div{
    top: 23%;
    left: 50%;
    width: 44px;
    height: 44px;
    background: transparent linear-gradient(270deg, #FFFFFF 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%,-50%);
}
.buton_call_sunet{

    width: 18px;
    height: 18px;
    position: relative;
    padding: 13px;

}

.button_border_background  {
    width: 60px;
    height: 60px;
    background: transparent linear-gradient(180deg, #000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000080;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
}

.button_border_gradient {
    width: 64px;
    height: 64px;
    background: transparent linear-gradient(180deg, #6C6C6C 0%, #484848 100%) 0% 0% no-repeat padding-box;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    left: 0px;
}