.dashboardElements {
    left: 6px;
    width: 580px;
    background-color: black;
    margin-right: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;

    pointer-events: auto;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.vehicleTop {
    height: 37px;
    width: 506px;
    left: 8.5px;
    top: 4px;
}

.vehicleBody {
    top: 1px;
    left: 60px;
    width: 520px;
    /* border-top: 0.8px solid #b2b2b2; */
    position: relative;
    overflow-y:hidden;    
}

.vehicleBody::-webkit-scrollbar {
    display: none;
}

/* .vehicleBody::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: rgba(0, 0, 0, 0.6) 0px -50px 36px -28px inset;  
} */

.droneBody {
    top: 1px;
    left: 60px;
    width: 520px;
    /* border-top: 1px solid #323136; */
    position: relative;
    overflow-y: hidden;
}

/* .droneBody::-webkit-scrollbar {
    display: none;
} */


.drone_inner_shadow {
    bottom: 0px;
    left: 61px;
    position: absolute;
    width: 519px;
    height: 40px;
    border-radius: 10px;
    background: transparent linear-gradient(180deg, #0D0D1300 0%, black 100%) 0% 0% no-repeat padding-box;
}

.drone_inner_shadow_up {
    top: 0px;
    left: 62px;
    position: absolute;
    width: 518px;
    height: 40px;
    border-radius: 10px;
    background: transparent linear-gradient(0deg, #0D0D1300 0%, black 100%) 0% 0% no-repeat padding-box;
}


.drone_expand {
    top: 20px;
    left: 10px;
    width: 482px;
    height: 135px;
    border-top: 1px solid #323136;
    position: relative;
    overflow: hidden;
}

.drona_img {
    top: 15px;
    left: 10px;
    width: 200px;
    height: 98px;
    position: relative;
}

.baterie_img {
    top: 85px;
    left: 100px;
    width: 18.75px;
    height: 10px;
    position: absolute;
}

/* .baterie_cerc {
    top: 5px;
    left: 55px;
    width: 110px;
    height: 110px;
    position: absolute;
} */

.drona_baterie {
    position: absolute;
    top: 25px;
    left: 25px;
    width: 170px;
    height: 73px;
    text-align: center;
    font-size: 55px;
    letter-spacing: 0px;
    color: #3AC9C9;
}

.specs_container {
    top: 0px;
    left: 215px;
    width: 60px;
    height: 128px;
    border: 1px solid #323136;
    position: absolute;
}

.specs_title {
    width: 60px;
    height: 10px;
    background: #323136 0% 0% no-repeat padding-box;

    text-align: center;
    letter-spacing: 0px;
    color: #E2E2E2;
}

.specs_value {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 22px;
    height: 13px;
    background: transparent linear-gradient(180deg, #40E5E6 0%, #31A6A6 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #3AC9C980;
    border-radius: 2px;

    text-align: center;
    font-size: 10px;
    letter-spacing: 0px;
    color: #1C1C1C;
    transform: translate(-50%, -40%);
}

.specs_unit {
    position: absolute;
    top: 48.5%;
    left: 70%;
    width: 6px;
    height: 8px;
    text-align: center;
    font-size: 7px;
    letter-spacing: 0px;
    color: #3AC9C9
}

.specs_board {
    position: relative;
    width: 60px;
    height: 13px;
    /* border-top: 0.5px solid #E2E2E2;
    border-bottom: 0.5px solid #E2E2E2; */
    text-align: center;
    font-size: 10px;
    letter-spacing: 0px;
    color: #E2E2E2;
    padding: 1.6px 0;
}

.specs_line {
    position: relative;
    width: 10px;
    height: 0px;
    border-top: 0.5px solid #E2E2E2;
    border-bottom: 0.5px solid #E2E2E2;
    left: 25px;
}

.specs_shadow1 {
    position: absolute;
    top: 0px;
    left: 15px;
    width: 30px;
    height: 42px;
    background: transparent linear-gradient(0deg, #1C1C1C00 0%, #1C1C1C 100%) 0% 0% no-repeat padding-box;
}

.specs_shadow2 {
    position: absolute;
    top: 76px;
    left: 15px;
    width: 30px;
    height: 42px;
    background: transparent linear-gradient(180deg, #1C1C1C00 0%, #1C1C1C 100%) 0% 0% no-repeat padding-box;
}

.specs_sageata {
    top: 46.5%;
    left: 19%;
    position: absolute;
    width: 6px;
    height: 10px;
}

.compass_img {
    top: 15px;
    right: 5px;
    width: 127px;
    height: 114px;
    position: absolute;
}

.compass_directions {
    width: 127px;
    height: 17px;
    color: var(---555555);
    text-align: center;
    letter-spacing: 0px;
    color: #555555;
    position: absolute;
}

.svg {
    width: 110px;
    height: 110px;
    transform: rotate(-90deg);
    overflow: initial;
}

.circle {
    stroke-width: 10px;
    fill: none;
}

.circle:nth-child(1) {
    stroke: #363636;
    stroke-dasharray: 314;
    stroke-dashoffset: 0;
    transition: stroke-dashoffset 0.5s ease-in-out;
}

.circle_box {
    position: absolute;
    top: 5px;
    left: 55px;
}



/* .droneBody::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: rgba(0, 0, 0, 0.6) 0px -50px 36px -28px inset;  
} */

.vehicleLabel {
    top: 10px;
    left: 8.5px;
    /* width: 39px; */
    height: 13px;
    color: #8d8d8d;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: medium;
    font-size: 12px;
    line-height: 13px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #8D8D8D;
    opacity: 1;
    position: absolute;
}

.topIcons {
    top: 3px;
    left: 51px;
    width: 128px;
    height: 24px;
    background: #1C1C1C 0% 0% no-repeat padding-box;
    border: 1px solid #323136;
    border-radius: 8px;
    opacity: 1;
    position: relative;
    display: flex;
    flex-direction: row;
}

.topIcons_copy {
    top: 3px;
    left: 51px;
    width: 96px;
    height: 24px;
    background: #1C1C1C 0% 0% no-repeat padding-box;
    border: 1px solid #323136;
    border-radius: 8px;
    opacity: 1;
    position: relative;
    display: flex;
    flex-direction: row;
}

.topIconsBackground {
    top: 2px;
    left: 2px;
    right: 0px;
    width: 30px;
    height: 20px;
    background: transparent linear-gradient(180deg, #E2E2E2 0%, #C3C3C3 100%) 0% 0% no-repeat padding-box;
    border-radius: 7px;
    opacity: 1;
    position: absolute;
}

.topIconsBackgroundStandard {
    top: 2px;
    right: 0px;
    width: 30px;
    height: 20px;
    background: transparent;
    position: absolute;

}

.icons {
    top: 4px;
    left: 9px;
    width: 12px;
    height: 12px;
    opacity: 1;
    position: absolute;
    fill: #1C1C1C important !;
}

.search {
    top: 3px;
    left: 485px;
    width: 25px;
    height: 25px;
    background: #1c1c1c 0% 0% no-repeat padding-box;
    background: #1C1C1C 0% 0% no-repeat padding-box;
    border: 0.5px solid #39383D;
    border-radius: 8px;
    opacity: 1;
    position: absolute;
}

.searchIcon {
    top: 6px;
    left: 6px;
    width: 13px;
    height: 13px;
    opacity: 1;
    position: absolute;
}

.labelActiv {
    bottom: -7px;
    left: 11px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: medium;
    font-size: 12px;
    line-height: 10px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #555555;
    position: relative;
}

.vehicleDiv {
    margin-bottom: 10px;
    width: 501px;
    height: 54px;
    left: 10px;
    background: #1C1C1C 0% 0% no-repeat padding-box;
    border: 1px solid #323136;
    border-radius: 8px;
    opacity: 1;
    position: relative;
}

.vehicleDiv_expand {
    margin-bottom: 10px;
    width: 501px;
    height: 182px;
    left: 10px;
    background: #1C1C1C 0% 0% no-repeat padding-box;
    border: 1px solid #323136;
    border-radius: 8px;
    opacity: 1;
    position: relative;
}

.nameLabel {
    top: 10px;
    left: 37px;
    height: 19px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: medium;
    font-size: 16px;
    line-height: 19px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    position: absolute;
}

.nameLabel_offline {
    top: 10px;
    left: 37px;
    height: 19px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: medium;
    font-size: 16px;
    line-height: 19px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #A9A9AB;
    opacity: 1;
    position: absolute;
}

.siteLabel {
    top: 32px;
    left: 37px;
    height: 13px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #A9A9AB;
    opacity: 1;
    position: absolute;
}

.btnsDiv {
    top: 11px;
    left: 348.39px;
    width: 147px;
    height: 29px;
    opacity: 1;
    position: absolute;
}

.labelActivVehicle {
    top: 1px;
    width: 40px;
    height: 10px;
    color: #ffffff;
    text-align: center;
    font-style: italic;
    font-variant: normal;
    font-weight: medium;
    font-size: 10px;
    line-height: 10px;
    font-family: Roboto;
    letter-spacing: 0.16px;
    text-transform: uppercase;
    position: absolute;
}


.labelSolutionat {
    top: 1px;
    width: 70px;
    height: 10px;
    color: #ffffff;
    text-align: center;
    font-style: italic;
    font-variant: normal;
    font-weight: medium;
    font-size: 10px;
    line-height: 10px;
    font-family: Roboto;
    letter-spacing: 0.16px;
    text-transform: uppercase;
    position: absolute;
}

.labelLive {
    top: 1px;
    width: 34px;
    height: 10px;
    color: #12e5dd;
    text-align: center;
    font-style: italic;
    font-variant: normal;
    font-weight: medium;
    font-size: 10px;
    line-height: 10px;
    font-family: Roboto;
    letter-spacing: 0.16px;
    text-transform: uppercase;
    position: absolute;
}

.labelOffline {
    top: 1px;
    width: 46px;
    height: 10px;
    color: #8D8D8D;
    text-align: center;
    font-style: italic;
    font-variant: normal;
    font-weight: medium;
    font-size: 10px;
    line-height: 10px;
    font-family: Roboto;
    letter-spacing: 0.16px;
    text-transform: uppercase;
    position: absolute;
}


.labelDiv {
    top: 8.54px;
    left: 0px;
    width: 36px;
    height: 12px;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    position: absolute;
}

.labelDiv_copy {
    top: -10px;
    left: 30px;
    width: 40px;
    height: 12px;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    position: relative;
}

.labelDiv_live {
    top: -10px;
    left: 30px;
    width: 34px;
    height: 12px;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    position: relative;
}
.labelDiv_offline {
    top: -10px;
    left: 30px;
    width: 46px;
    height: 12px;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    position: relative;
}
.labelDiv_solutionat {
    top: -10px;
    left: 30px;
    width: 70px;
    height: 12px;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    position: relative;
}

.cameraBtnDiv {
    left: 44px;
    width: 29px;
    height: 29px;
    opacity: 1;
    position: absolute;
}

.callBtnDiv {
    left: 81.07px;
    width: 29px;
    height: 29px;
    opacity: 1;
    position: absolute;
}

.locationBtnDiv {
    left: 118.07px;
    width: 29px;
    height: 29px;
    opacity: 1;
    position: absolute;
}

.locationBtnDiv_copy {
    left: 81.07px;
    width: 29px;
    height: 29px;
    opacity: 1;
    position: absolute;
}

.btn {
    width: 29px;
    height: 29px;
    opacity: 1;
    position: absolute;
}

.labelPregatit {
    left: 11px;
    width: 20px;
    height: 10px;
    color: #555555;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: medium;
    font-size: 8px;
    line-height: 10px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #555555;
    opacity: 1;
}


.activAnimation {
    top: 16px;
    left: 10px;
    width: 20px;
    height: 20px;
    opacity: 1;
    position: relative;
}

.dcLeft{
    top: 0px;
    left: 0px;
    width: 60px;
    position: absolute;
    border-right: 1px solid #323136;
}
.dcLabel{
    color: #12E5DD;
    position: absolute;
    top: 60px;
    left: 0px;
    width: 60px;
    text-align: center;
    font-size: 14px;
}
.dcFilters{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100px;
    left: 5px;
    width: 50px;
    height: 200px;
    background: #1C1C1C 0% 0% no-repeat padding-box;
    border: 1px solid #323136;
    border-radius: 8px;
}
.search_copy {
    left: 5px;
    bottom: 5px;
    width: 50px;
    height: 50px;
    background: #1c1c1c 0% 0% no-repeat padding-box;
    border: 0.5px solid #39383D;
    border-radius: 50%;
    position: absolute;
}
.searchIcon_copy {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 20px;
    height: 20px;
    position: absolute;
}

.icons_copy {
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    opacity: 1;
    position: relative;
    /* fill: #1C1C1C important !; */
}
.icons_copy_active{
    top: 0px;
    -webkit-transition: top 0.3s ease-out, left 0.25s ease-in-out;
       -moz-transition: top 0.3s ease-out, left 0.25s ease-in-out;
        -ms-transition: top 0.3s ease-out, left 0.25s ease-in-out;
         -o-transition: top 0.3s ease-out, left 0.25s ease-in-out;
            transition: top 0.3s ease-out, left 0.25s ease-in-out;
    left: 0px;
    width: 20px;
    height: 20px;
    padding: 15px;
    opacity: 1;
    position: absolute;
    background: transparent linear-gradient(180deg, #FBFBFB 0%, #DDDDDD 0%, #838383 100%) 0% 0% no-repeat padding-box;
    border-radius: 6px;
}
.main_icon{
    top: 5px;
    left: 5px;
    width: 50px;
    height: 50px;
    position: absolute;
}
.btn_copy {
    width: 48px;
    height: 48px;
    opacity: 1;
    position: relative;
    margin: 1px;
}
.btnsDiv_copy {
    top: 2.2px;
    left: 348.39px;
    width: 150px;
    height: 50px;
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
}

.divVehiclesAlert {
    height: 204px;
    top: 20px;
    width: 336px;
    left: 169.73px;
    background-color: #12E5DD;
    position: relative;
}


/* Lista Vehicule */

.nameLabelVehicleAlert {
    position: absolute;
    left: 0px;
    top: 3px;
    width: 173px;
    height: 24px;

    font: medium 18px/19px Roboto;
    letter-spacing: 0;
    color: #FFFFFF;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: medium;
    font-size: 20px;
    line-height: 19px;
    font-family: Roboto;
}

.siteLabelVehicleAlert {
    position: absolute;
    left: 0px;
    top: 24px;
    height: 16px;

    letter-spacing: 0;
    color: #A9A9AB;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: medium;
    font-size: 14px;
    line-height: 13px;
    font-family: Roboto;

    position: absolute;
}

.btnsAlerts {
    top: 0px;
    width: 48px;
    height: 48px;
    background: #121212 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    position: absolute;
}

.divAlertsVehicleDiv {
    left: -11px;
    margin-top: 2px;
    width: 336px;
    height: 48px;
    opacity: 1;
    position: relative;
    /* border-left: 1px solid #323136; */
}

.divVehiclesALertBig {
    display: flex;
    flex-direction: column;
    top: 3px;
    left: 169.73px;
    width: 336px;
    position: relative;
}



.alerts_expand_inner_shadow {
    bottom: -1px;
    left: 158.5px;
    position: absolute;
    width: 343px;
    height: 25px;
    border-radius: 8px;
    /* background: transparent linear-gradient(180deg, #0D0D1300 0%, black 100%) 0% 0% no-repeat padding-box;
    opacity: 0.7; */
    background: transparent linear-gradient(180deg, #1C1C1C00 0%, #1C1C1C 100%) 0% 0% no-repeat padding-box;
}
.alerts_expand_inner_shadow_sus {
    top: 57px;
    left: 159px;
    position: absolute;
    width: 343px;
    height: 25px;
    border-radius: 8px;
    /* background: transparent linear-gradient(0deg, #0D0D1300 0%, black 100%) 0% 0% no-repeat padding-box;
    opacity: 0.7; */
    background: transparent linear-gradient(0deg, #1C1C1C00 0%, #1C1C1C 100%) 0% 0% no-repeat padding-box;
}

.scrollDiv {
    border-top: 1px solid #323136;
    height: 187px;
    overflow-y:auto;
    position: relative;
    top: 24px;
}

.scrollDiv::-webkit-scrollbar {
    display: none;
}

.divFoculete {
    left: 12.38px;
    width: 134px;
    height: 32px;
    top: 188.5px;
    position: absolute;
}

.labelSeveritate {
    top: 0px;
    left: 0px;
    width: 31px;
    height: 9px;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 9px;
    font-family: Roboto;
    text-align: left;
    letter-spacing: 0px;
    color: #8D8D8D;
    opacity: 1;
    position: absolute;
}

.divIconsFoculete {
    top: 8.25px;
    left: 5.28px;
    width: 129px;
    height: 24px;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.fireIcon {
    margin-right: 4.78px;
    position: relative;
    bottom: 0px;
    /* background: transparent linear-gradient(180deg, #FA5901 0%, #E21212 100%) 0% 0% no-repeat padding-box; */
}