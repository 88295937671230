.locatie_bgr {
	overflow: visible;
	position: absolute;
	/* top: 30px;
    left: 32px; */
    width: 311px;
height: 311px;
/* UI Properties */
background: transparent linear-gradient(180deg, #DBDBDB 0%, #313131 47%, #4D4D4D 100%) 0% 0% no-repeat padding-box;
opacity: 1;

    -webkit-mask-image: url("../graphics/layout/locatie-inel-top.svg");
    mask-image: url("../graphics/layout/locatie-inel-top.svg");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;  

	/* background-color: black; */
}

.circular_div {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    /* padding: 20px; */
    width: 227px;
    height: 227px;
    margin: 20px;
}

.circular_div_background{
    width: 227px;
    height: 227px;
    background: #1C1C1C 0% 0% no-repeat padding-box;
    /* background: red 0% 0% no-repeat padding-box; */

    box-shadow: 0px 1px 3px #E8E8E841;
    border: 1px solid #000000;
    border-radius: 50%;
}

.compass_values{
    color: #12E5DD;
    font-size: 20px;
    position: relative;
    top: 110px;
    left: 22px;
    text-align: center;
}

.compass_ring {
    width:207px;
    height: 207px; 
    top: 31px;
    left: 31px; 
    position: absolute;
}

.compass_sageata{
    width: 233px;
    height: 233px;
    top: 28px; 
    left: 18px;
    position: absolute;
}